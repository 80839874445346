<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
                page-title-box
                d-sm-flex
                align-items-center
                justify-content-between
              ">
                        <h4 class="mb-sm-0">Transfer Saham</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Form Transfer Saham</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Edit Pengguna</h4>
                            <div class="flex-shrink-0">
                                <router-link :to="{ name: 'share' }" type="button"
                                    class="btn btn-warning waves-effect waves-light"><i
                                        class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                    Kembali
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="" @submit.prevent="submit" v-if="share != null">
                                <div class="form-group mb-3">
                                    <label class="form-label">Pemilik</label>
                                    <input v-model="share.owner.name" type="text" class="form-control" readonly />
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-label">Nomor Sertifikat</label>
                                    <input v-model="share.share_number" type="text" class="form-control" readonly />
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Periode</label>
                                            <input v-model="share.share_period.name" type="text" class="form-control"
                                                readonly />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Jumlah Slot</label>
                                            <input v-model="share.slot" type="text" class="form-control" readonly />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-label">Harga Beli</label>
                                    <CurrencyInput v-model="transfer.total_price" :placeholder="'Masukkan Harga Beli'"
                                        :options="currency_config" />
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-label">Pemilik Baru</label>
                                    <div>
                                        <vue3-simple-typeahead :items="investor"
                                            :placeholder="'Masukkan nama investor...'" @selectItem="selectItem"
                                            :minInputLength="0" :itemProjection="
                                                (item) => {
                                                    return item.name;
                                                }
                                            " />
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <h5 class="fs-14 mb-1">Bukti Transfer</h5>
                                    <p class="text-muted">Tambahkan gambar bukti transfer.</p>
                                    <div class="row mb-1">
                                        <div class="col-md-5">
                                            <div class="
                        profile-user
                        position-relative
                        d-inline-block
                        mx-auto
                        mb-4
                      ">
                                                <img class="img-thumbnail" alt="logo" width="150"
                                                    :src="image_preview ?? '/assets/images/small/img-4.jpg'"
                                                    data-holder-rendered="true" />
                                                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                    <input id="profile-img-file-input" type="file"
                                                        class="profile-img-file-input"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        @change="filesChange($event)" />
                                                    <label for="profile-img-file-input"
                                                        class="profile-photo-edit avatar-xs">
                                                        <span class="
                              avatar-title
                              rounded-circle
                              bg-light
                              text-body
                            ">
                                                            <i class="ri-camera-fill"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="float-end">
                                    <router-link :to="{ name: 'share' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button :disabled="load" type="submit"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                                        Simpan
                                    </button>
                                </div>
                            </form>

                            <FormShimmer :count="10" v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import httpService from "../../services/http-service";
import { endpoint } from "../../host";
import Swal from "sweetalert2";
import errorService from "../../services/error-service";
import FormShimmer from "../../components/shimmer/FormShimmer.vue";
import Vue3SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import CurrencyInput from "../../components/CurrencyInput.vue";

export default {
    components: {
        FormShimmer,
        Vue3SimpleTypeahead,
        CurrencyInput,

    },
    created() {
        var params = this.$route.params;
        this.id = params.id;
        this.showShare();
        this.getInvestor();
    },
    data() {
        return {
            id: null,
            title: null,
            load: false,
            share: null,
            image_preview: null,
            transfer: {
                user_id: null,
                total_price: null,
                evidence_of_transfer: null,
            },
            investor: [],
            currency_config: {
                currency: "IDR",
            },
        };
    },
    methods: {
        async getInvestor() {
            try {
                let res = await httpService.get(httpService.generateEndpointQuery(endpoint.user, {
                    role: 'investor',
                    limit: 1000,
                }));
                this.investor = res.data.data;
            } catch (error) {
                errorService.displayError(error.response);
            }
        },

        selectItem(item) {
            this.transfer.user_id = item.id;
        },

        async showShare() {
            let res = await httpService.get(endpoint.share + `/show/${this.id}`);
            this.share = res.data.data;
        },

        async submit() {
            try {
                this.load = true;
                let formData = new FormData();
                for (const key in this.transfer) {
                    formData.append(key, this.transfer[key] ?? "");
                }
                let res = await httpService.post(endpoint.share + `/transfer/${this.id}`, formData);
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "data berhasil disimpan",
                        icon: "success",
                    });
                    this.$router.push({
                        name: "share",
                        params: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },

        filesChange(event) {
            this.transfer.evidence_of_transfer = event.target.files[0];
            this.image_preview = URL.createObjectURL(this.transfer.evidence_of_transfer);
        },
    },
};
</script>
  

<style>
.simple-typeahead-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.9rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--vz-body-color);
    background-color: var(--vz-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--vz-input-border);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

.simple-typeahead-input:focus-visible {
    outline: none;
}
</style>